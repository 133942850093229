.design {
    width: 70%;
    height: 80vh;
    border: 2px solid $color_black;
    margin-top: 10vh;
    margin-left: calc(15vw + ($size_of_nav_admin/2));
    background: var(--url) no-repeat;
    background-size: 50%;
    background-position: 30%;
    overflow: hidden;



    .img-drag {
        width: 100px;
        height: 100px;
        cursor: pointer;
        z-index: 10;
        position: fixed;
        overflow: visible;
        top: 40%;
        left: 40%;

        img {
            width: 20%;
        }
    }

    .valid {
        position: absolute;
        width: 300px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 75%;
        left: 55%;
    }

    .tool-bar {
        width: 40%;
        height: 80px;
        position: absolute;
        top: 10%;
        border-right: 2px solid $color_black;
        border-bottom: 2px solid $color_black;
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        padding-top: 20px;

        i {
            font-size: 30px;
            cursor: pointer;
            margin-top: -20px;
        }

        .container-color {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 40%;
            height: 100px;
            margin-left: 10%;
            margin-bottom: 40px;

            .color {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: var(--clr);
                transition: .5s;
                border: 7px double white;
                cursor: pointer;

                &:hover {

                    border: 7px double var(--clr);
                }
            }
        }


    }



    .right-bar {
        border-left: 2px solid $color_black;
        width: 20%;
        height: 80vh;
        margin-left: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .img {
            width: 80%;
            height: 20vh;
            background: var(--url);
            background-size: cover ;
            cursor: pointer;
        }

    }

}
