.button {
    color: $color_white;
    background: $color_light_blue;
    width: 200px;
    height: 50px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    transition: .5s;
    border: 2px solid $color_light_blue;


    &:hover {
        transform: scale(1.2);
        background-color: $color_white;
        color: $color_light_blue;
    }
}
