.container-cookie {
    width: 300px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $color_light_blue;
    transition: 2s;
    position: fixed;
    top: calc(95vh - 400px);
    left: 20px;
    border-radius: 20px;
    z-index: 100;


    img {
        width: 50%;
        animation: anim-cookie 2s infinite;
    }

    h2,h4 {
        width: 80%;
        text-align: center;
        color: $color_white;
    }

    h4 {
        font-size: 12px;
    }

    .accept {
        color: $color_black;
        background-color: $color_white;
        border-radius: 20px;
        width: 80%;
        margin-top: 20px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .5s;
        font-size: 20px;

        &:hover {
            background-color: $color_black;
            color: $color_white;
        }
    }

    &.active {
        transform: translateX(-100vw);
    }

}

@keyframes anim-cookie {
    to {
        transform: rotate(360deg);
    }
}
