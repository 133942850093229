
.product-card {
    width: calc(80vw/5);
    height: 450px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    box-shadow: $big_box_shadow;
    border-radius: 20px;
    margin-left: calc(120vw/30);
    margin-top: 100px;
    cursor: pointer;
    background: var(--url);
    background-size: cover;
    background-position: center;
    transition: .5s;
    overflow: hidden;

    @media screen and (max-width: 1400px) {
        width: calc(80vw/3);
    }

    @media screen and (max-width: 1200px ) {
        width: calc(80vw/2);
    }

    @media screen and (max-width: 800px) {
        width: 80vw;
    }


    &:hover {
        transform: translateY(-20px);
    }

    .img {
        transition: .1s;
        overflow : hidden;
    }



    .step-txt {
        position: relative;
        top: 45%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 30%;
        transform: translateY(var(--dec));

        .title {
            font-size: 20px;
            top: 63%;
        }


        .price {
            width: 150px;
            height: 40px;
            background: $color_light_orange;
            border : 2px solid $color_light_orange;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color_white;
            transition: .5s;
            margin-top: 20px;
            cursor: pointer;
            top: 65%;

            &:hover {
                color: $color_light_orange;
                background: $color_white;
                transform: scale(1.2);
            }
        }

    }

    .like {
        width: 30px;
        height: 30px;
        background-color: $color_pink;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .5s;
        position: relative;
        top: 10%;
        left: 40%;

    }

}
