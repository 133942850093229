.home-container {
    width: 100vw;
    height: calc(80vh - $size_of_nav);
    background: $color_white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $size_of_nav;


    .left-container {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;

        @media screen and (max-width: 500px ) {
            width: 100%;
        }

        .title {
            font-size: 30px;
            color: $color_black;
            margin-bottom: 30px;
            margin-left: 10%;
            font-weight: bold;
        }

        .description {
            width: 80%;
            margin-bottom: 50px;
            margin-left: 10%;
            font-size: 20px;
        }

        .button {
            margin-left: 10%;
        }
    }

    .right-container {
        width: 50%;
        height: 100%;
        overflow-y:hidden;

        @media screen and (max-width: 500px ) {
            display: none;
        }

        .circle {
            width: 100vh;
            height: 100vh;
            position: relative;
            background: $color_light_blue;
            border-radius: 50%;
            z-index: 2;
            transform: translateX(-10px) translateY(5%);


        }

        .container-picture {
            width: 70%;
            height: 50%;
            overflow: hidden;
            border: 2px solid $color_grey;
            transform: translateY(40%);
            position: relative;
            border-radius: 20px;
            z-index: 3;
            box-shadow: $big_box_shadow;
            margin-left: 30px;

            @media screen and (max-width: 500px ) {
                display: none;
            }
        }

        .picture {
            width: 100%;
            height: 100%;
            background: url("https://media.discordapp.net/attachments/1113357364536541204/1113357926514556948/white_vierge_teef.png?width=741&height=741");
            background-size: cover;
            position: relative;
            cursor: pointer;
            z-index: 3;
            margin: auto;
            transition: 1s;

            &:nth-child(1) {
                animation: 10s slide-t-shirt infinite;
            }

            &:nth-child(2) {

                background: url("https://media.discordapp.net/attachments/1113357364536541204/1113357611748831313/blue_fhoodie_vierge.png?width=741&height=741");
                background-size: cover;
                animation: 10s slide-t-shirt2 infinite;
            }


        }



        @keyframes slide-t-shirt {
            0% {
                transform: translateX(100%);
            }

            45% {
                transform: translateX(100%);
            }

            50% {
                transform: translateX(0%);
            }

            95% {
                transform: translateX(0%);
            }

            100% {
                transform: translateX(100%);
            }
        }

        @keyframes slide-t-shirt2 {
            0% {
                transform: translateX(0) translateY(-100%);
            }

            40% {
                transform: translateX(0) translateY(-100%);
            }

            50% {
                transform: translateX(100%) translateY(-100%);
            }

            90% {
        transform: translateX(100%) translateY(-100%);
            }

            100% {
                transform: translateX(0) translateY(-100%);
            }
        }
    }

}

.collection-container {
    width: 100vw;
    height: 80vh;
    overflow: hidden;

    .title {
        font-size: 30px;
        font-weight: bold;
        color: $color_black;
        margin-left: 30px;
        margin-top: 2%;
    }

}

.big-container {
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1500px;

    @media screen and (max-width: 920px) {
        flex-direction: column;
    }

    .card {
        background: $color_grey;
        box-shadow: 2px 2px 10px #ddd, -2px -2px 10px #ddd;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 20px;
        cursor: pointer;
        transition: .5s;

        &:hover {
            transform: rotateX(10deg) rotateY(-10deg) rotateZ(3deg);
        }


        div:nth-child(1) {
            font-weight: bold;
            font-size: 40px;
        }

        .button {
            margin-top: 50px;
        }


        &:nth-child(1) {
            width: 40%;
            height: 80%;
            display: flex;
            @media screen and (max-width: 920px) {
                margin-top: 20px;
                width: 400px;
                margin-left: 0;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 400px) {
                div {
                    font-size: 16px;
                }
                width: 80%;
            }
        }


        &:nth-child(2) {
            width: 40%;
            height: 80%;
            display: flex;
            margin-left: 10%;
            @media screen and (max-width: 920px) {
                width: 400px;
                margin-left: 0;
                margin-bottom: 20px;
            }
            @media screen and (max-width: 400px) {
                width: 80%;
                div {
                    font-size: 16px;
                }
            }
        }
    }




}

