.container-pay-page {
    width: 100vw;
    height: 100vh;
    margin-top: -$size_of_nav;


    .left {
        width: 60%;
        max-height: 100vh;
        overflow-y: hidden;
        border-right: 2px solid $color_black;

        @media screen and (max-width: 800px) {
            width: 100vw;
        }


        &::-webkit-scrollbar {
            display: none;
        }



        .step {
            width: 100%;
            position: absolute;
            transition: .5s;
            transform: translateX(100vw);
            padding-left: 50px;
            height: 73vh;
            overflow-y: scroll;
            margin-top: -80px;

            @media screen and (max-width: 800px) {
                width: 100vw;
            }


            .label {
                font-weight: bold;
                font-size: 16px;
                margin-top: 50px;
            }

            input,.input-radio {
                color: $color_dark_grey;
                background-color: $color_light_grey;
                box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
                border: none;
                border-radius: 4px;
                padding: 1em;
                margin-top: 24px;
                width: 50%;
                outline-color: $color_light_blue;
            }

            .input-radio {
                display: flex;
                justify-content: start;
                align-self: center;
                padding-left: 20px;
                cursor: pointer;

                .point {
                    width: 10px;
                    height: 10px;
                    border: 2px solid $color_light_blue;
                    border-radius: 50%;
                    margin-right: 20px;
                    transition: .5s;
                    background-color: $color_white;
                }

                .point.active {
                    background-color: $color_light_blue;
                }

                .text-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-self: center;
                }
            }

            .btn {

                font-weight: 600;
                text-align: center;
                font-size: 19px;
                color: $color_white;
                background: $color_light_blue;
                width: 20%;
                border-radius: 4px;
                padding: 0.8em;
                cursor: pointer;
                overflow: hidden;
                transition: .2s;
                margin-top: 40px;
                margin-bottom: 20px;

                &:hover {
                    box-shadow: 0 6px 10px rgba(0,0,0,0.3);
                    transform: translateY(-4px)
                }
            }

            .container-box {
                width: 60vw;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 20px;
                @media screen and (max-width: 800px) {
                    flex-direction: column;
                }

                .box {
                    width: 30%;
                    height: 300px;
                    border: 2px solid $color_light_blue;
                    display: flex;
                    justify-content: space-around;
                    align-items: start;
                    flex-direction: column;
                    border-radius: 20px;
                    @media screen and (max-width: 800px) {
                        width: 70%;
                        margin-bottom: 20px;
                    }

                    .title {
                        align-self: center;
                        font-weight: bold;
                        color: $color_light_blue;
                    }

                    .info {
                        margin-left: 40px;
                    }
                }
            }

            img {
                width: 40%;
                margin-bottom: 40px;
            }

        }

        .step.active {
            transform: translateX(0);
        }





    }

    .right {
        width: 40%;
        height: calc(100vh - $size_of_nav);
        position: absolute;
        left: 60%;
        top: $size_of_nav;
        background-color: $color_white;
        border-left: 2px solid $color_black;

        @media screen and (max-width: 800px) {
            display: none;
        }

        .title {
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            margin-top: 50px;
        }

        .line {
            width: 70%;
            height: 2px;
            background: $color_black;
            margin: 30px auto auto;
        }

        .container-article-right {
            display: flex;
            width: 100%;
            height: 100px;
            justify-content: space-around;
            align-items: center;
            overflow: hidden;


            .img {
                margin-top: 30px;
                width: 70px;
                height: 70px;
                background: var(--url);
                background-size: cover;
            }

            #total {
                color: $color_light_blue;
            }
        }

    }

}


