.container-newsletter {
    background: $color_light_blue;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding-left: 50px;
    height: 40vh;
    transform: translateX(-100vw);
    transition: .5s;
    color: $color_white;

    div {
        margin-bottom: 20px;
    }

    .title {
        font-weight: bold;
        font-size: 30px;
    }

    .form {
        display: flex;
        justify-content: center;
        align-items: start;

        @media screen and (max-width: 650px){
            flex-direction: column;
        }

        input {
            width: 400px;
            height: 45px;
            padding-left: 20px;
            margin-right: 30px;

            @media screen and (max-width: 490px) {
                width: 70vw;
            }
        }

        .button {
            width: 100px;

            &:hover {
                transform: none;
            }
        }

    }

    &.active {
        transform: translateX(0);
    }

}
