.slogan {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:  $color_white;
    width: 100vw;
    height: 60vh;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
        justify-content: center;
        flex-direction: column;

    }

    .box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: visible;

        i {
            margin-bottom: 40px;
            font-size: 25px;
        }

        .title {
            font-weight: bold;
            font-size: 30px;
        }

        .description {
            font-size: 24px;
        }
    }
}
