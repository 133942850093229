.container-msg-alert {
    width: 500px;
    height: 200px;
    background: $color_white;
    border-radius: 20px;
    box-shadow: 2px 2px 20px #222 , -2px -2px 20px #fff;
    position: fixed;
    top: calc(50% - 100px);
    left: calc(50vw - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    transform: translateY(-1000px) scale(0);
    overflow: hidden;
    cursor: pointer;
    color: var(--color-black-2);
    font-size: 25px ;
    text-align: center;
    z-index: 20;


    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -16px;
        right: -16px;
        background: $color_light_blue;
        height: 32px;
        width: 32px;
        border-radius: 32px;
        transform: scale(2);
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-out;
    }

    &:hover:before {
        transform: scale(35);
    }

    i {
        position: absolute;
        top: 20px;
        left: 20px;
    }



}

.container-msg-alert.active {
    transform: translateY(0) scale(1);
}


@media screen and (max-width : 1000px) {
    .container-msg-alert.active {
        transform: translateY(0) scale(.5);
    }
}
