.load {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: $color_white;
    animation: load-animation 2s;
    animation-fill-mode: forwards;
    z-index: 200;

    .point:nth-child(2) {
        animation-delay: .4s
    }

    .point:nth-child(3) {
        animation-delay: .8s
    }

    .point {
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        background: $color_light_blue;
        animation: point-animation 1.5s;
        animation-fill-mode: forwards;
        margin-left: calc(100% / 5);
        position: relative;
        transition: .5s;
        display: inline-block;
        top: -100px;
    }
}

@keyframes point-animation {
    0% {top: -100px;}
    35% {top: -100px;}
    41%{top: 50vh;}
    43%{top: 40vh;}
    45%{top: 55vh;}
    47%{top: 50vh;}
    100%{top: 50vh;}
}

@keyframes load-animation {
    80% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(200vw);
    }
}
