.nb {
    text-align: center;
    margin-top: calc($size_of_nav + 40px);
    font-size: 24px;
}

.container-product-search {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80vw;
    margin-left: 20vw;
    margin-top: $size_of_nav;
    padding-bottom: 50px;
    min-height: 70vh;

    @media screen and (max-width: 1400px) {
        margin-top: calc($size_of_nav + 300px);
        margin-left: 0;
        width: 100vw;
    }

    @media screen and (max-width: 1200px ) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.container-product-search.like {
    grid-template-columns: repeat(4, 1fr);
    width: 100vw;
    margin: 50px auto auto;
}

