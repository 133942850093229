.forum {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
    overflow-y: hidden;

    .container-pres-forum {
        width: 50vw;
        height: 50vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: $color_light_blue;

        @media screen and (max-width: 900px ) {
            display: none;
        }

        .title {
            font-weight: bold;
            font-size: 30px;
            color: $color_black;
        }

        .btn {
            margin-top: 20px;
            width: 200px;
            height: 50px;
            background: $color_white;
            border: 2px solid $color_light_blue;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: $color_black;
            cursor: pointer;
            transition: .5s;

            &:hover {
                transform: scale(1.2);
            }
        }
    }

    .container-forum {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 30px;
        width: 50vw;
        height: 50vh;
        background: $color_light_blue;
        overflow: hidden;

        @media screen and (max-width: 900px ) {
            width: 100vw;
        }

        .container-msg {
            width: 100%;
            height: 80%;
            overflow-y: scroll;
            margin-top: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            .msg {
                width: 400px;
                background: $color_white;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                padding: 5px 5px 5px 20px;
                font-weight: bold;
                margin-bottom: 20px;


                .content {
                    font-weight: normal;
                }
            }

        }

        .container-write  {
            transform: translateY(43px);

            input {
                position :relative;
                width: 50vw;
                height: 45px;
                padding-left: 20px;
            }

            .send {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: $color_light_blue;
                display: flex;
                justify-content: center;
                align-items: center;
                color : $color_white;
                transition: .5s;
                transform: translateY(-200px) translateX(48vw);
                cursor: pointer;
            }

            .send.active {
                transform: translateY(-45px) translateX(48vw);
            }
        }
    }
}
