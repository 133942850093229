.page-privacy {

    h2 {
        margin-top: 100px;
        text-align: center;
        font-size: 30px;
    }

    h4 {
        margin-top: 10%;
        font-size: 16px;
        padding-left: 10%;
        padding-right: 10%;
    }
}
