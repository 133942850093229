@font-face {
    font-family: 'my-font';
    src : url("../assets/font.otf");
}


* {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    text-decoration: none;
    font-family: 'my-font';
}

i {
    cursor: pointer;
}


$size_of_nav_admin  : 200px;
$size_of_nav        : 80px;
$big_box_shadow     : 20px 30px 60px rgba(0,0,0,0.16);

$color_blue         : #2e2493;
$color_light_blue   : #5a4cff;
$color_red          : #ee3142;
$color_orange       : #f6725a;
$color_light_orange : $color_light_blue;
$color_yellow       : #ffe76b;
$color_green        : #69D598;
$color_pink         : #F4B4CC;
$color_grey         : #f5f5f5;
$color_light_grey   : #e8eeef;
$color_dark_grey    : #384047;
$color_white        : #fff;
$color_black        : #1a1a1a;


::-webkit-scrollbar {
    width: 8px;
    background: $color_white;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: $color_black;
    border-radius: 20px;
}


