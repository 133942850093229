
.tools {
    width: 20vw;
    position: absolute;
    background: $color_white;
    margin-top: calc($size_of_nav + 100px);
    box-shadow: $big_box_shadow;
    border-radius: 0 20px 20px 0;
    padding-top : 40px;


    @media screen and (max-width: 1400px) {
        width: 100vw;
        box-shadow: none;
        border-bottom: 2px solid $color_black;
        height: 300px;
        border-radius: 0;
        overflow: hidden;
        padding-top: -30px;
    }

    .container-step {
        width: 100%;

        .title {
            font-size: 22px;
            font-weight: bold;
            margin-left: 20px;
        }

        .container-size {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .size {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80%;
                height: 50px;
                border-radius: 20px;
                background: $color_grey;
                margin : 20px 0 20px 0;
                cursor: pointer;
            }
        }

        .container-color {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 80%;
            margin-left: 10%;
            margin-bottom: 40px;

            .color {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: var(--clr);
                transition: .5s;
                border : 7px double white;
                cursor: pointer;

                &:hover {

                    border : 7px double var(--clr);
                }
            }


        }


    }

}
