.container-product {
    width: 100%;
    height: 95vh;
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    margin-left: $size_of_nav_admin;



    .cat {
        padding-top: 20px;
        width: 100%;

        .container {
            padding-bottom: 50px;
            margin-top: 20px;


            .article {
                border-radius: 20px;
                width: 200px;
                height: 200px;
                background: var(--url);
                background-size: cover;
                cursor: pointer;
                transition: .5s;

                &:hover {
                    transform: translateY(20px);
                }
            }
        }

    }

}

.button-add {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $color_light_blue;
    color: $color_white;
    font-size: 25px;
    animation: 2s button-add-animation infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 90vh;
    left: 95vw;
    cursor: pointer;
    z-index: 11;
}


@keyframes button-add-animation {
    20% {transform: rotate(30deg);}
    30% {transform: rotate(-30deg);}
    40% {transform: rotate(15deg);}
    50% {transform: rotate(-15deg) scale(1.2);}
    60% {transform: rotate(7deg) scale(1.2);;}
    70% {transform: rotate(-7deg);}
    80% {transform: rotate(0);}
}


.container-add.active {
    transform: scale(1);
}

.container-add {
    width: 300px;
    height: 500px;
    background:$color_light_blue;
    border-radius: 20px;
    position: fixed;
    z-index: 10;
    top: calc(90vh - 500px);
    left: calc(95vw - 300px);
    transform: scale(0);
    transition: .5s;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;



    .title {
        color: $color_white;
    }

    input,select {
        color: $color_dark_grey;
        background-color: $color_light_grey;
        box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
        border: none;
        border-radius: 4px;
        padding: 1em;
        width: 80%;
        margin-top: 24px;
        outline-color: $color_black;

        &:last-child,select {
            width:90%;
        }
    }

    .send {
        font-weight: 600;
        text-align: center;
        font-size: 19px;
        color: $color_white;
        background: $color_blue;
        width: 80%;
        border: none;
        border-radius: 4px;
        padding: 0.8em;
        cursor: pointer;
        overflow: hidden;
        transition: .2s;
        box-shadow: 0 2px 6px rgba(0,0,0,0.3);
        margin-top: 40px;

        &:hover {
            box-shadow: 0 6px 10px rgba(0,0,0,0.3);
            transform: translateY(-4px)
        }
    }


}



