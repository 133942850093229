.file {
    margin-top: $size_of_nav;
    width: 30vw;
    height: 30vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10vw;

    @media screen and (max-width: 800px) {
        width: 60vw;
    }


    .container {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        overflow: visible;


        .line {
            width: 100%;
            height: 2px;
            background: $color_black;
            position: relative;
            top: 65%;
            transform: translateX(-55%);
        }

        .text {
            color: $color_dark_grey;
        }


        .circle {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $color_white;
            color: $color_dark_grey,;
            border : 2px solid $color_dark_grey;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2;
            font-size: 20px;
        }

    }
}
