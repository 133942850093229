.page-404-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: $color_light_orange;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
        font-size: 100px;
        color: $color_white;
        margin-bottom: 50px;
    }

    .subTitle {
        font-size: 250%;
        color: $color_white;
        margin-bottom: 50px;
        text-align: center;
    }

    .btn {
        text-decoration: none;
        background: $color_white;
        color: $color_light_orange;
        border : 2px solid $color_light_orange;
        width: 250px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .5s;

        &:hover {
            background: $color_light_orange;
            color: $color-white;
            border : 2px solid $color_white;
        }
    }

}
