.nav-link {
    width: 100%;
    height: $size_of_nav;
    background: $color_white;
    border-bottom: 2px solid $color_black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 11;



    .step-item {

        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 30%;

        @media screen and (max-width:1100px ) {
            width: 50%;
        }
        @media screen and (max-width: 600px) {
            width: 70%;
        }


        .item {
            height: calc($size_of_nav * .7);
            text-decoration: none;
            color: $color_black;
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            font-size: 18px;

            i {
                width: 30px;
                height: 30px;
                background: var(--url);
                background-size: cover;
            }

            @media screen and (max-width: 450px) {
                span {
                    display: none;
                }
            }



            &:nth-child(1) {
                font-weight: bold;
                font-size: 24px;
            }
        }

        img {
            width: 100px;
            cursor: pointer;
        }

    }

    .step-icon {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 10%;
        height: 100%;
        background: var(--url);
        background-size: cover;

        @media screen and (max-width:900px ) {
            width: 30%;
        }

        i {
            cursor: pointer;
        }
    }

}


.secret-nav {
    width: 100vw;
    height: calc(4 * $size_of_nav);
    border-bottom: 3px solid $color_black;
    position: fixed;
    background: $color_white;
    top: $size_of_nav;
    z-index: 10;
    transition: .5s;
    transform: translateY(-(4*$size_of_nav));
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-left : 40px;

    &.active {
        transform: translateY(0);
    }


    a {
        margin-top: 40px;
        cursor: pointer;
        text-decoration: none;
        color: $color_black;

        &:nth-child(1) {
            font-weight: bold;
        }
    }
}


.admin-nav {
    width: $size_of_nav_admin;
    height: 100vh;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    background: $color_light_blue;
    position: fixed;
    z-index: 10;

    .btn {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-top: 50px;
        font-size: 20px;
        margin-left: 25px;
        cursor: pointer;
        transition: .2s;
        color: $color_white;

        &:hover {
            color: $color_black;
        }

        i {
            padding-right: 20px;
        }
    }
}


