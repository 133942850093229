table {
    margin-top: 50px;
    margin-left: calc($size_of_nav_admin + 20px);
    width: calc(100vw - $size_of_nav_admin - 40px);
    max-height: 70vh;
    overflow :scroll;
}


table thead tr, table tbody tr {
    display: table-row;
    background: $color_light_grey;
}

table tbody tr:nth-of-type(odd) {
    background: $color_light_grey;
}

table thead tr {
    font-weight: 900;
    color: var(--color-white);
    background: $color_light_blue;
}

table thead tr th, thead tbody tr td {
    padding: 6px 12px;
    display: table-cell;
}
