.admin-page {

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


    .container-form {
        width: 400px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: $big_box_shadow;
        border-radius: 20px;

        .title {
            margin-bottom: 30px;
        }

        h3 {
            margin-top: 30px;
            cursor: pointer;
            color: $color_light_blue;

            &:hover {
                text-decoration: underline;
            }
        }


        h4 {
            margin-top: 10px;
            margin-left: -50%;
            cursor: pointer;
        }

        input {
            color: $color_dark_grey;
            background-color: $color_light_grey;
            box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
            border: none;
            border-radius: 4px;
            padding: 1em;
            width: 80%;
            margin-top: 24px;
            outline-color: $color_light_blue;
        }

        .send {
            font-weight: 600;
            text-align: center;
            font-size: 19px;
            color: $color_white;
            background: $color_light_blue;
            width: 80%;
            border: none;
            border-radius: 4px;
            padding: 0.8em;
            cursor: pointer;
            overflow: hidden;
            transition: .2s;
            box-shadow: 0 2px 6px rgba(0,0,0,0.3);
            margin-top: 40px;

            &:hover {
                box-shadow: 0 6px 10px rgba(0,0,0,0.3);
                transform: translateY(-4px)
            }

        }

    }


}

