.scroll-view-collection {

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    scroll-direction: horizontal;
    overflow-x: scroll;



    .collection_card {
        width: 350px;
        height: 400px;
        border-radius: 20px;
        box-shadow: $big_box_shadow;
        background: $color_white;
        margin-top: 70px;
        margin-right: 20px;
        margin-left: 20px;
        display: flex;
        padding-bottom: 40px;
        justify-content: end;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

    }
}



