.container-user {
    width: 70vw;
    height: 70vh;
    border-radius: 20px;
    box-shadow: $big_box_shadow;
    margin: calc($size_of_nav + 50px) auto 100px;
    overflow: hidden;


    .head {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 2px solid $color_black;

        h2 {
            @media screen and (max-width: 800px) {
                display: none;
            }
        }
    }


    .container-command {
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow-y:scroll ;

        .container-table {
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            transform: translateX(-7.5%);
        }


        h2 {
            margin-top: 20px;
            align-self: start;
            margin-left: 40px;

        }


        .command {
            margin-top: 40px;
            width: 300px;
            height: 100px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: $color_light_blue;
            border-radius: 20px;
            color: $color_white;
            cursor: pointer;
            transition: .5s;

            &:hover {
                transfrm: scale(0.9);
            }
        }
    }
}
