.container-article {
    width: 100vw;
    height: 70vh;
    background: $color_white;
    margin-top: $size_of_nav;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        height: 120vh;
    }


    .img {
        background: var(--url);
        background-size: cover;
        transform: translateX(-75%);
        background-position: center;
        position: relative;
        z-index: 3;
        width: 350px;
        height: 400px;
        border-radius: 20px;
        transition: .5s;
        box-shadow: $big_box_shadow;
        cursor: pointer;

        @media screen and (max-width: 1400px) {
            transform: scale(1.2) translateX(-20%);
        }
        @media screen and (max-width:110px) {
            transform: scale(1.2) ;
        }
        @media screen and (max-width: 1000px) {
            box-shadow: none;
        }
        @media screen and (max-width: 500px) {
            transform: translateX(5vw);
        }



        &:hover {
            background: var(--url2);
            background-size: cover;
            background-position: center;
        }

        &:hover div {
            opacity: 0;
        }

        div {
            transition: .1s;
        }
    }

    .information {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        transform: translateX(20%);

        @media screen and (max-width: 1000px) {
            transform: translateX(0) translateY(10vh);
        }

        .like {
            width: 30px;
            height: 30px;
            background-color: $color_pink;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: .5s;
            position: relative;
            left: 90%;

            @media screen and (max-width: 1000px) {
                display: none;
            }
        }

        .description {
            width: 100%;
            height: 200px;
            font-size: 24px;
            display: flex;
            justify-content: start;
            margin-top: 50px;
            padding-left: 20px;
            align-items: center;
        }

        .title {
            font-weight: bold;
            font-size: 40px;
            margin-bottom: 100px;
            overflow: hidden;
        }

        .price {
            margin-bottom: 30px;
            font-size: 24px;
            overflow: hidden;
        }

        .container-color {
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            margin-bottom: 40px;

            .color {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: var(--clr);
                transition: .5s;
                border: 7px double white;
                cursor: pointer;

                &:hover {

                    border: 7px double var(--clr);
                }
            }
        }

        .container-command {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: visible;

            @media screen and (max-width: 450px) {
                flex-direction: column;
            }

            .container-nb {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100px;
                height: 50px;
                border-radius: 50px;
                background: $color_white;
                border : 2px solid $color_light_blue;
                color: $color_black;
                font-size: 24px;
                transition: .5s;
                margin-top: 30px;
                margin-right: 50px;

                i {
                    font-size: 16px;
                    cursor: pointer;
                }
            }


            .command {
                width: 300px;
                height: 50px;
                border-radius: 50px;
                background: $color_light_blue;
                border : 2px solid $color_light_blue;
                color: $color_white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                cursor: pointer;
                transition: .5s;
                margin-top: 30px;

                &:hover {
                    background: $color_white;
                    color: $color_light_blue;
                }

        }


        }

    }



}
